import { useKeenSlider } from 'keen-slider/react'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useElementSize } from '@kaliber/use-element-size'
import { easeInOutQuart } from '/machinery/easings'
import { trackInteractionSlided } from '/machinery/trackInteractionSlided'
import { useInterval } from '/machinery/useInterval'
import { HeadingXl } from '/features/buildingBlocks/Heading'
import { Image } from '/features/buildingBlocks/Image'
import { ContainerMd } from '/features/buildingBlocks/Container'

import 'keen-slider/keen-slider.min.css'
import media from '/cssGlobal/media.css'
import styles from './SliderWithCompanies.css'

export function SliderWithCompanies({ items, title }) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [autoPlay, setAutoPlay] = React.useState(true)
  const [paddedItems, setPaddedItems] = React.useState([])
  const { ref: sizeRef, size: { width } } = useElementSize()
  const isMd = useMediaQuery(`(min-width: ${media.breakpointMd})`)
  const delay = 2000
  const duration = 300
  const { logoSizeSm, logoSizeMd } = styles
  const logoSize = parseInt(isMd ? logoSizeMd : logoSizeSm, 10)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    slides: {
      number: paddedItems?.length,
      origin: 'center',
      perView: 'auto',
    },
    selector: x => x.childNodes,
    defaultAnimation: {
      duration,
      easing: easeInOutQuart,
    },
    animationEnded() { setAutoPlay(true) },
    dragStarted() { setAutoPlay(false) },
    dragEnded(s) {
      setAutoPlay(true)
      trackInteractionSlided({ index: s.track.details.rel, title, action: 'dragged' })
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
  })

  useInterval(
    () => {
      if (autoPlay && instanceRef?.current) {
        instanceRef.current.moveToIdx((currentSlide + 1) % paddedItems?.length)
      }
    },
    delay
  )

  React.useEffect(
    () => {
      if(!items?.length)
        return

      const repeat = (arr, repeats) => [].concat(...Array.from({ length: repeats }, () => arr))
      const padding = width / (logoSize * (items.length))
      setPaddedItems(repeat(items, Math.ceil(padding)))
    },
    [items, width, logoSize]
  )

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef, paddedItems, isMd]
  )

  return (
    <div ref={sizeRef} className={styles.component}>
      <ContainerMd layoutClassName={styles.container}>
        <header className={styles.header}>
          {title && <HeadingXl layoutClassName={styles.title} h='3'>{title}</HeadingXl>}
        </header>
      </ContainerMd>
      <div ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
        {paddedItems && paddedItems.map((item, index) => (
          <Slide
            key={index}
            layoutClassName={cx(styles.slide, index === 0 && styles.firstSlide)}
            logo={item.logo}
          />
        ))}
      </div>
    </div>
  )
}

function Slide({ logo, layoutClassName }) {
  return (
    <div className={cx(styles.componentSlide, layoutClassName)}>
      <div className={styles.containerLogo}>
        {logo && <Image image={logo} imgProps={{ loading: 'lazy' }} />}
      </div>
    </div>
  )
}
